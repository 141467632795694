<template>
  <div class="fill-height">
    <v-container class="pa-6">
      <div class="text-h5 font-weight-bold">Your Allocations</div>
      <v-card v-for="pool in vm.allPools" outlined :key="pool.model.id" class="mt-6">
        <v-data-table
          :headers="headers"
          :items="pool.purchases"
          hide-default-footer
          class="purchases-table"
          :loading="pool.loading"
        >
          <template v-slot:top>
            <div class="pa-4 pb-0 d-flex">
              <div style="min-width: 50px">
                <app-logo height="24" :avatar="pool.model.logoUrl || pool.model.file" />
              </div>
              <div class="text-subtitle-2">{{ pool.model.name }}</div>
            </div>
          </template>
          <template v-slot:item.index="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <span v-if="pool.claimAirdrop"> Automated Distribute </span>
              <span v-else-if="item.claimed"> Claimed </span>
              <!-- <v-btn
                v-else
                :loading="item.claiming"
                color="primary"
                :disabled="!item.canRedeemTokens"
                small
                rounded
                @click="item.claimToken()"
                >Claim Tokens</v-btn
              > -->
              <div v-else>
                <ClaimButton :item="item" />
              </div>
            </div>
          </template>
          <template v-slot:item.purchase.validAfterDate="{ item }">
            <span v-if="item.purchase.validAfterDate">
              {{ item.purchase.validAfterDate | ddmmyyyyhhmmss }}
            </span>
            <span v-else>TBA</span>
          </template>
          <template v-slot:item.purchase.ethAmount="{ item }">
            {{ item.purchase.ethAmount | formatNumber }} {{ pool.tradeToken }}
          </template>
          <!-- <template v-slot:item.swap="{ item }">
                <v-btn :loading="item.loading" color="primary" small rounded @click="swap">Swap</v-btn>
              </template> -->
        </v-data-table>
      </v-card>
      <div class="d-flex mt-6 justify-center">
        <v-btn color="primary" :loading="vm.loading" v-if="vm.canLoadMore" @click="vm.fetchPools()">
          Load More
          <template v-slot:loader>
            <span>Loading...</span>
          </template>
        </v-btn>
      </div>
    </v-container>
    <v-divider class="mt-7" />
    <company-footer />
  </div>
</template>

<script>
import { AllAllocationsViewModel } from '../viewmodels/all-allocations-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import poolState from '@/modules/ido/components/pool-state.vue'
import ClaimButton from '@/modules/market/components/claim-button.vue'

@Observer
@Component({
  components: {
    poolState,
    ClaimButton,
  },
})
export default class AllPools extends Vue {
  @Provide() vm = new AllAllocationsViewModel()

  searchKey = ''
  headers = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Valid From', value: 'purchase.validAfterDate', sortable: false },
    { text: 'Action', value: 'action', sortable: false },
    // { text: 'Swap', value: 'swap', sortable: false }
  ]

  showDetail(item) {
    if (item.pool.address) {
      this.$router.push({ path: '/pool/' + item.pool.id })
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
</script>

<style scoped lang="scss">
.progress-mobile {
  width: 120px;
}
.progress-desktop {
  width: 200px;
}
</style>
